$scope-name: 'str-video__speaker-layout';

.str-video__speaker-layout__wrapper {
  flex-grow: 1;
  overflow-y: hidden;
}

.str-video__speaker-layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  gap: 1rem;
  padding-inline: 2px; // to see "speaking" outline
  padding: 2px;

  .str-video__participant-view {
    aspect-ratio: 16/9;
  }

  .str-video__speaker-layout__spotlight {
    display: flex;
    justify-content: center;
    min-height: 0;
    height: 100%;

    .str-video__participant-view--speaking:has(
        .str-video__video--screen-share
      ) {
      outline: none;
    }

    .str-video__video {
      height: 100%;
      width: 100%;
    }

    .str-video__participant-details,
    .str-video__call-controls__button {
      opacity: 1;
    }
  }

  .str-video__speaker-layout__participants-bar-buttons-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    & > .str-video__call-controls__button {
      .str-video__icon {
        width: 1rem;
        height: 1rem;
      }
    }

    .str-video__speaker-layout__participants-bar--button-left {
      position: absolute;
      left: 0.5rem;
    }
    .str-video__speaker-layout__participants-bar--button-right {
      position: absolute;
      right: 0.5rem;
    }
    .str-video__speaker-layout__participants-bar--button-top {
      position: absolute;
      top: 0.5rem;
    }
    .str-video__speaker-layout__participants-bar--button-bottom {
      position: absolute;
      bottom: 0.5rem;
    }
  }

  .str-video__speaker-layout__participants-bar-wrapper {
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    .str-video__speaker-layout__participants-bar {
      display: flex;
      align-items: center;

      .str-video__speaker-layout__participant-tile {
        width: 280px;
        min-width: 280px;
        max-width: 25vh;
        // to see "speaking" outline
        padding: 5px;
      }
    }
  }

  &--variant-top {
    flex-direction: column-reverse;
  }
  &--variant-left {
    flex-direction: row-reverse;
  }
  &--variant-right {
    flex-direction: row;
  }

  &--variant-left,
  &--variant-right {
    .str-video__speaker-layout__participants-bar-wrapper {
      overflow-y: auto;
      max-height: 100%;
    }
    .str-video__speaker-layout__participants-bar {
      flex-direction: column;
    }
    .str-video__speaker-layout__spotlight {
      width: 100%;
    }
    .str-video__participant-view {
      max-width: unset;
    }
  }

  &--variant-top,
  &--variant-bottom {
    .str-video__speaker-layout__participants-bar-wrapper {
      overflow-x: auto;
    }
    .str-video__speaker-layout__participants-bar {
      flex-direction: row;
    }
    .str-video__participant-view {
      max-width: 110vh;
    }
  }
}

@media (min-width: 600px) {
  .str-video__speaker-layout {
    .str-video__speaker-layout__spotlight {
      align-items: center;
      height: auto;

      .str-video__video {
        object-fit: contain;
      }
    }
  }
}
