.str-video__icon {
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-position: center;
  mask-size: contain;
  -webkit-mask-size: contain;
  display: block;
  width: 1.25rem;
  height: 1.25rem;
}
