.str-video__reaction {
  position: absolute;
  right: 0.875rem;
  top: 0.875rem;

  .str-video__reaction__emoji {
    display: flex;
    line-height: 1;
    font-size: 2.5rem;
  }
}
