.str-video__screen-share-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  gap: 1.8rem;

  & > .str-video__icon {
    width: 4rem;
    height: 4rem;
  }

  .str-video__screen-share-overlay__button {
    display: flex;
    gap: 0.4rem;
    align-items: center;
    padding-inline: 1.6rem;
    padding-block: 0.8rem;

    .str-video__icon {
      width: 0.7rem;
      height: 0.7rem;
    }
  }
}
