.str-video__notification {
  padding: 0.625rem;
  display: flex;
  align-items: center;
  gap: 0.625rem;

  .str-video__notification__icon {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
  }

  .str-video__notification__message {
    flex: 1;
  }

  .str-video__notification__close {
    display: inline-block;
    width: 1rem;
    height: 1rem;

    &:hover {
      cursor: pointer;
    }
  }
}
