.str-video__call {
  background-color: var(--str-video__background-color2);
  color: var(--str-video__text-color1);
  width: 100%;
  height: 100%;
  min-height: 0;
  max-height: 100%;

  display: flex;
  flex-direction: column;

  .str-video {
    background-color: var(--str-video__background-color2);
    color: var(--str-video__text-color1);
  }
}
