.rd__incoming-video-settings__button {
  .str-video__composite-button__button-group {
    .str-video__composite-button__button {
      width: 80px;
      height: 20px;
      gap: 6px;
      white-space: nowrap;
      justify-content: flex-start;
    }
  }
}
