@use '../utils';

.str-video {
  --str-video__search-input__container--color: var(--str-video__text-color1);
  --str-video__search-input__container--background-color: var(
    --str-video__background-color0
  );
  --str-video__search-input__container--border-radius: var(
    --str-video__border-radius-circle
  );
  --str-video__search-input__container--border-block-start: 1px solid
    transparent;
  --str-video__search-input__container--border-block-end: 1px solid transparent;
  --str-video__search-input__container--border-inline-start: 1px solid
    transparent;
  --str-video__search-input__container--border-inline-end: 1px solid transparent;

  --str-video__search-input__placeholder-color: var(--str-video__text-color2);

  --str-video__search-input__icon--color: var(--str-video__text-color1);
}

.str-video__search-input__container.str-video__search-input__container--active {
  border-color: var(--str-video__primary-color);
}
.str-video__search-input__container {
  @include utils.component-layer-overrides('search-input__container');

  input {
    background-color: transparent;
    border: none;
    outline: none;
    color: inherit;
    font-size: 13px;

    &::placeholder {
      color: var(--str-video__search-input__placeholder-color);
    }
  }

  .str-video__search-input__icon {
    @include utils.apply-mask-image(
      var(--str-video__icon--magnifier-glass),
      var(--str-video__search-input__icon--color),
      0.75rem 0.75rem
    );
  }
  .str-video__search-input__clear-btn {
    background-color: transparent;
    border-radius: var(--str-video__border-radius-circle);
    border: none;

    &:hover {
      cursor: pointer;
    }

    .str-video__search-input__icon--active {
      @include utils.apply-mask-image(
        var(--str-video__icon--close),
        var(--str-video__search-input__icon--color),
        0.75rem 0.75rem
      );
    }
  }
}
