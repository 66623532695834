@use '../utils';

.str-video__loading-indicator__icon.spinner {
  @include utils.apply-mask-image(
    var(--str-video__icon--loading),
    var(--str-video__text-color2)
  );
  animation: rotation 1s linear infinite;
  width: 1rem;
  height: 1rem;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
