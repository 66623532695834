.str-video__call-panel {
  background: var(--str-video__background-color3);
  border: 2px solid rgba(200,200,200,0.6);
  border-radius: 10px;
}

.str-video__call-panel--ringing {
  .str-video__call-panel__members-list {

    .str-video__call-panel__member-box {
      border-radius: var(--str-video__border-radius-circle);
      background: var(--str-video__background-color6);

      &:before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: var(--str-video__border-radius-circle);
        padding: 4px;
        background:linear-gradient(
            180deg,var(--str-video__primary-color),var(--str-video__info-color)
        );
        -webkit-mask:
          linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
      }

      .str-video__member_details {
        background-color: var(--str-video__background-color4);
        color: var(--str-video__text-color1);
        border-radius: 4px;
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}
