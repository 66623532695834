.str-video__video-placeholder {
  background: var(--str-video__base-color6);
  border-radius: inherit;

  .str-video__video-placeholder__avatar {
    border-radius: var(--str-video__border-radius-circle);
  }

  .str-video__video-placeholder__initials-fallback {
    background-color: var(--str-video__primary-color);
    border-radius: var(--str-video__border-radius-circle);
    font-size: 32px;
    font-weight: 600;
    text-transform: uppercase;
  }
}
