.str-video__livestream-layout__wrapper {
  flex-grow: 1;
  overflow-y: hidden;

  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;

  .str-video__livestream-layout__screen-share {
    max-width: 100%;

    .str-video__video {
      object-fit: contain;
    }
  }

  .str-video__livestream-layout__floating-participant {
    position: absolute;
    width: 240px;
    height: 135px;
    box-shadow: var(--str-video__background-color1) 0 0 3px 0;

    &--top-right {
      top: 8px;
      right: 8px;
    }

    &--top-left {
      top: 8px;
      left: 8px;
    }

    &--bottom-right {
      bottom: 8px;
      right: 8px;
    }

    &--bottom-left {
      bottom: 8px;
      left: 8px;
    }
  }

  .str-video__livestream-layout__overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;

    &:hover .str-video__livestream-layout__overlay__bar {
      background-color: var(--str-video__livestream-overlay-color-hovered);
    }

    .str-video__livestream-layout__overlay__bar {
      height: 10%;
      min-height: 40px;
      max-height: 70px;
      position: relative;
      bottom: 0;
      border-bottom-left-radius: var(--str-video__border-radius-sm);
      border-bottom-right-radius: var(--str-video__border-radius-sm);

      background-color: var(--str-video__livestream-overlay-color);

      display: flex;
      align-items: center;
      padding: 0 20px;
    }

    .str-video__livestream-layout__live-badge {
      padding: 4px 8px;
      border-radius: var(--str-video__border-radius-xxs);
      background-color: var(--str-video__primary-color);
      color: var(--str-video__text-color1);
    }

    .str-video__livestream-layout__viewers-count {
      &::before {
        content: var(--str-video__icon--livestream-viewers);
        margin-right: 3px;
        vertical-align: middle;
      }

      padding: 4px 8px;
    }

    .str-video__livestream-layout__speaker-name {
      flex: 1;
      font-size: 13px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .str-video__livestream-layout__duration {
      flex: 1;
      text-align: center;
    }

    .str-video__livestream-layout__go-fullscreen {
      background: var(--str-video__icon--fullscreen) center no-repeat;
      border-radius: var(--str-video__border-radius-xxs);
      cursor: pointer;
      width: 32px;
      height: 32px;

      &:hover {
        background-color: var(--str-video__overlay-color);
      }
    }
  }
}
