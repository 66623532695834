@use '../utils';

.str-video__participant-listing-item {
  .str-video__participant-listing-item__display-name {
    font-size: 13px;
  }

  .str-video__participant-listing-item__media-indicator-group {
    .str-video__participant-listing-item__icon-mic {
      @include utils.apply-mask-image(
          var(--str-video__icon--mic),
          var(--str-video__text-color1),
          contain,
      );
    }

    .str-video__participant-listing-item__icon-mic-off {
      @include utils.apply-mask-image(
          var(--str-video__icon--mic-off),
          var(--str-video__danger-color),
          contain,
      );
    }

    .str-video__participant-listing-item__icon-camera {
      @include utils.apply-mask-image(
          var(--str-video__icon--camera),
          var(--str-video__text-color1),
          contain,
      );
    }

    .str-video__participant-listing-item__icon-camera-off {
      @include utils.apply-mask-image(
          var(--str-video__icon--camera-off),
          var(--str-video__danger-color),
          contain,
      );
    }

    .str-video__participant-listing-item__icon-pinned {
      @include utils.apply-mask-image(
          var(--str-video__icon--pin),
          var(--str-video__text-color1),
          contain,
      );
    }
  }
}

