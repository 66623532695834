$scope-name: 'str-video__participant-view';

.str-video__participant-view {
  position: relative;
  width: 100%;
  max-width: 1280px;
  max-height: 100%;
  min-height: 0;
  aspect-ratio: 4/3;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--str-video__border-radius-sm);

  &--speaking {
    outline: 2px solid var(--str-video__primary-color);
  }

  .str-video__call-controls__button {
    transition: opacity 200ms ease-out;
    opacity: 0.3;
    color: var(--str-video__text-color1);
  }

  &:hover {
    .str-video__call-controls__button {
      opacity: 1;
    }
  }

  & > .str-video__call-controls__button {
    position: absolute;
    top: 0.875rem;
    left: 0.875rem;
    padding: 0.3rem;
  }

  .str-video__participant-details {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    gap: var(--str-video__spacing-sm);
    border-radius: 0 var(--str-video__border-radius-xs) 0
      var(--str-video__border-radius-sm);
    background-color: var(--str-video__background-color4);

    .str-video__participant-details__name {
      display: flex;
      align-items: center;
      gap: var(--str-video__spacing-sm);
      padding: 4px 6px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: var(--str-video__font-size-sm);

      @mixin icon {
        $icon-size: 1rem;
        width: $icon-size;
        height: $icon-size;
        mask-size: $icon-size;
        -webkit-mask-size: $icon-size;
        background-color: var(--str-video__text-color1);
      }

      .str-video__participant-details__name--audio-muted {
        @include icon();
        mask-image: var(--str-video__icon--mic-off);
        -webkit-mask-image: var(--str-video__icon--mic-off);
      }

      .str-video__participant-details__name--video-muted {
        @include icon();
        mask-image: var(--str-video__icon--camera-off);
        -webkit-mask-image: var(--str-video__icon--camera-off);
      }

      .str-video__participant-details__name--pinned {
        cursor: pointer;
        @include icon();
        width: 0.8rem;
        -webkit-mask-size: 0.8rem;
        height: 1rem;
        mask-size: 0.8rem;
        mask-repeat: no-repeat;
        -webkit-mask-repeat: no-repeat;
        mask-position: center;
        -webkit-mask-position: center;
        mask-image: var(--str-video__icon--pin);
        -webkit-mask-image: var(--str-video__icon--pin);
      }

      .str-video__participant-details__name--network-stats {
        @include icon();
        background-image: var(--str-video__icon--network-quality);
        cursor: pointer;
        margin-right: 0.625rem;
      }
    }
  }

  .str-video__participant-details__connection-quality {
    position: absolute;
    bottom: 0;
    right: 0;

    border-radius: var(--str-video__border-radius-sm) 0
      var(--str-video__border-radius-sm) 0;

    width: 24px;
    height: 24px;
    display: block;
    background-size: cover;
    background-color: var(--str-video__background-color4);

    &--poor {
      background-image: var(--str-video__icon--connection-quality-poor);
    }
    &--good {
      background-image: var(--str-video__icon--connection-quality-good);
    }
    &--excellent {
      background-image: var(--str-video__icon--connection-quality-excellent);
    }
  }
}

.str-video__speech-indicator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1px;
  height: 16px;

  .str-video__speech-indicator__bar {
    width: 3px;
    height: 20%;
    background-color: var(--str-video__primary-color);
    border-radius: 2px;
    animation-duration: 0.4s;
    animation-iteration-count: 1;
    animation-name: speech-indicator-silence;
    animation-timing-function: ease-in-out;
  }

  &.str-video__speech-indicator--dominant {
    .str-video__speech-indicator__bar {
      background-color: var(--str-video__primary-color);
    }
  }

  &.str-video__speech-indicator--speaking {
    .str-video__speech-indicator__bar {
      height: 100%;
      animation-iteration-count: infinite;

      &:nth-child(1) {
        height: 70%;
        animation-duration: 0.8s;
        animation-name: speech-indicator-quiet;
      }
      &:nth-child(2) {
        height: 80%;
        animation-duration: 1s;
        animation-name: speech-indicator-loud;
      }
      &:nth-child(3) {
        height: 60%;
        animation-duration: 0.9s;
        animation-name: speech-indicator-quiet;
      }
    }
  }

  @keyframes speech-indicator-silence {
    25% {
      transform: scaleY(2);
    }
    50% {
      transform: scaleY(2.5);
    }
    75% {
      transform: scaleY(1);
    }
  }

  @keyframes speech-indicator-quiet {
    25% {
      transform: scaleY(0.5);
    }
    50% {
      transform: scaleY(0.3);
    }
    75% {
      transform: scaleY(0.5);
    }
  }

  @keyframes speech-indicator-loud {
    25% {
      transform: scaleY(1);
    }
    50% {
      transform: scaleY(0.45);
    }
    75% {
      transform: scaleY(1.1);
    }
  }
}
