.str-video__button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--str-video__border-radius-md);
  padding: var(--str-video__spacing-sm) var(--str-video__spacing-lg);
  text-align: center;
  min-width: 120px;
  width: 100%;
  font-size: var(--str-video__font-size-md);
  font-weight: 500;
  border: 1px solid transparent;
  color: var(--str-video__text-color1);

  background-color: var(--str-video__button-primary-base);

  &:not([disabled]):hover {
    background-color: var(--str-video__button-primary-hover);
    cursor: pointer;
  }

  &__icon {
    margin-right: var(--str-video__spacing-xs);
    background-color: var(--str-video__text-color1);
  }

  &:disabled {
    background-color: var(--str-video__button-default-disabled);
    cursor: not-allowed;
  }
}

.str-video__composite-button {
  &--caption {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0.25rem;
  }

  .str-video__composite-button__button-group {
    display: flex;
    align-items: center;

    .str-video__composite-button__button {
      all: unset;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      padding: 0.5rem;

      &:not([disabled]):hover {
        cursor: pointer;
      }
    }

    .str-video__menu-toggle-button {
      padding: 0;
      margin-left: -30px;
      margin-right: 8px;
    }

    .str-video__loading-indicator__icon {
      width: 1.375rem;
      height: 1.375rem;
      -webkit-mask-size: 1.25rem;
      mask-size: 1.25rem;
    }
  }

  &.str-video__composite-button--menu .str-video__composite-button__button {
    padding-right: 2.5rem;
  }
}

.str-video__call-controls__button {
  padding: 0.5rem;

  &[disabled] {
    cursor: not-allowed;
  }

  &:hover,
  &:hover > *:hover {
    cursor: pointer;
  }
}

.str-video__text-button {
  all: unset;
  padding: 5px;
  font-size: 12px;
  border-radius: var(
    --str-video__participant-list-header__close-button--border-radius
  );
  background: var(
    --str-video__participant-list-header__close-button--background-color
  );
}

.str-video__call-recording-list-item__action-button {
  border: none;
}
