.str-video__call-recording-list__list {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;

  &:first-of-type {
    .str-video__call-recording-list__item {
      font-weight: 600;
    }
  }
}

.str-video__call-recording-list__item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: var(--str-video__font-size-xs);
  border-bottom: 1px solid var(--str-video__base-color2);
  padding: var(--str-video__spacing-sm) 0;
  gap: var(--str-video__spacing-sm);

  &:last-of-type {
    border-bottom: none;
  }
}
.str-video__call-recording-list__filename {
  width: 200px;
}

.str-video__call-recording-list__time {
  width: 100px;
}

.str-video__call-recording-list__download {
  display: flex;
  justify-content: flex-end;
  width: 100px;
}

.str-video__call-recording-list__filename,
.str-video__call-recording-list__time {
  text-overflow: ellipsis;
  overflow: hidden;
}

.str-video__call-recording-list-item__action-button--download {
  .str-video__icon--download {
    height: 32px;
    width: 32px;
  }
  &:hover {
    .str-video__icon--download {
      cursor: pointer;
      background-color: var(--str-video__brand-color1);
    }
  }
}
