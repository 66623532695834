$scope-name: 'str-video__paginated-grid-layout';

.str-video__paginated-grid-layout__wrapper {
  flex-grow: 1;
}

.str-video__paginated-grid-layout {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;

  .str-video__paginated-grid-layout__group {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: center;
    // prevents horizontal overflow
    max-width: 110vh; // TODO: consider applying different limits (2 participants "unset", 4 participants 110vh...) 
    padding-inline: 1.25rem;
    margin: auto; // when arrows aren't rendered this element jumps to the left
    width: 100%; // prevent resizing of placeholders 

    .str-video__participant-view {
      flex: 0 1 calc(25% - 6px);
    }

    &.str-video__paginated-grid-layout--one {
      .str-video__participant-view {
        flex: 0 1 calc(100% - 6px);
      }
    }
    &.str-video__paginated-grid-layout--two-four {
      .str-video__participant-view {
        flex: 0 1 calc(50% - 6px);
      }
    }
    &.str-video__paginated-grid-layout--five-nine {
      .str-video__participant-view {
        flex: 0 1 calc(33% - 6px);
      }
    }
  }
}
