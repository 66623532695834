.str-video__tooltip {
  display: flex;
  justify-content: center;
  padding: var(--str-video__spacing-sm);
  z-index: 4;
  max-width: 250px;
  width: max-content;
  white-space: initial;
  overflow-wrap: break-word;
}
