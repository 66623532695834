@use '../utils';

.str-video__participant-listing-item {
  display: flex;
  justify-content: space-between;
  gap: var(--str-video__spacing-sm);
  padding-block: 0.5rem;
  width: 100%;
  align-items: center;

  .str-video__call-controls__button {
    padding: unset;
  }

  .str-video__participant-listing-item__display-name {
    flex: 1;
    @include utils.ellipsis-text();
  }

  .str-video__participant-avatar {
    display: flex;
    align-items: center;
    gap: var(--str-video__spacing-sm);
  }

  .str-video__participant-listing-item__media-indicator-group {
    display: flex;
    align-items: center;
    gap: var(--str-video__spacing-sm);

    .str-video__participant-listing-item__icon {
      width: 0.75rem;
      height: 0.75rem;
    }
  }
}
