.str-video__video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  pointer-events: none;

  &--tall {
    object-fit: contain;
  }

  &--mirror:not(:picture-in-picture) {
    transform: scaleX(-1);
  }
}
