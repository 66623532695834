.str-video__call-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 400px;
  height: 300px;
  padding: 1rem 1rem 0.5rem;
  gap: 1rem;
}

.str-video__call-panel--ringing {
  .str-video__call-panel__members-list {
    overflow: hidden;
    display: flex;
    align-items: center;

    .str-video__call-panel__member-box {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 2rem;

      .str-video__avatar {
        width: 80px;
        height: 80px;
      }

      .str-video__member_details {
        position: absolute;
        bottom: 10%;
        padding: 0.125rem 0.375rem;
      }
    }
  }

  .str-video__pending-call-controls {
    display: flex;
    gap: 0.675rem;
  }
}
