$scope-name: 'str-video__participant-list';

.str-video__participant-list {
  display: flex;
  flex-direction: column;

  .str-video__participant-list-header {
    display: flex;
    align-items: center;

    .str-video__participant-list-header__title {
      flex: 1;
      font-size: var(--str-video__font-size-sm);
    }
    .str-video__participant-list-header__close-button {
      > span {
        height: 15px;
        width: 15px;
      }
    }
  }

  .str-video__search-input__container {
    margin-top: var(--str-video__spacing-md);
  }

  .str-video__participant-list__content-header {
    display: none;
    align-items: center;
    gap: 0.5rem;
    margin-top: var(--str-video__spacing-md);
    justify-content: flex-end;

    .str-video__participant-list__content-header-title {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .str-video__call-controls__button {
      width: 1.75rem;
      height: 1.75rem;
      display: flex;
      padding: unset;
      align-items: center;
      justify-content: center;

      .str-video__icon--filter {
        width: 1rem;
        height: 1rem;
      }
    }
  }

  .str-video__participant-list__content {
    height: auto;
    flex-grow: 1;
    overflow-y: auto;
    margin-top: var(--str-video__spacing-xl);
  }

  .str-video__participant-list__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: 0.5rem;
  }

  .str-video__search-results--loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .str-video__loading-indicator__icon {
      $indicator-size: 3rem;
      height: $indicator-size;
      width: $indicator-size;
      mask-size: $indicator-size;
      -webkit-mask-size: $indicator-size;
    }
  }

  .str-video__participant-list--empty {
    display: flex;
    align-items: center;
    width: 100%;
    padding: var(--str-video__spacing-md);
    font-size: var(--str-video__font-size-sm);
  }
}

.str-video__invite-link-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.375rem;
  width: 100%;
  position: relative;
  padding-block: 0.5rem;

  .str-video__invite-link-tooltip {
    position: absolute;
    bottom: 110%;
  }

  .str-video__invite-participant-icon {
    height: 20px;
    width: 20px;
  }
}
